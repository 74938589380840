/* Ensure the header is fixed */
#site_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  background-color: var(--bg-color); /* Solid background color */
  padding: 0.5rem 0 1rem;
  transition: all 0.3s ease-in-out;
}

/* Adjust navbar for sticky behavior */
.sticky {
  background-color: var(--bg-color); /* Ensure it's visible on scroll */
  padding: 0.5rem 0 1rem;
}

/* Mobile menu styles */
.menu_items {
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--bg-color);
    z-index: 9999;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-width: 250px;
    padding: 2rem;
  }

  &.active {
    transform: translateX(0);
  }
}

/* Mobile menu close button */
.menu_items .close-menu {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--white-color);
  z-index: 10000; /* Ensure it sits on top of everything */

  @media (max-width: 992px) {
    display: block;
  }
}

/* Handle potential scroll issues */
body.menu-open {
  overflow: hidden; /* Prevent background scroll when mobile menu is open */
}

/* Existing Navbar styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .navbar_brand img {
    max-width: 35%;
  }
  
  .navbar_toggler {
    display: none;
    cursor: pointer; /* Add this line for the pointer cursor */

    & svg {
      line-height: 2.8571rem;
      margin-right: 1.0714rem;
      text-align: center;
      width: 1.4286rem;
      height: 1.4286rem;
      border-radius: 50%;

      & path {
        fill: var(--white-color);
      }
    }

    @media (max-width: 992px) {
      display: block;
    }
  }
  
  .menu_items {
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        display: inline-block;
        position: relative;
        padding: 0 1rem;

        @media (max-width: 992px) {
          display: block;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding: 1rem 0;
        }

        a {
          padding: 0.675rem 0;
          font-size: 1.1rem;
          font-weight: 500;
          color: var(--white-color);
          cursor: pointer;

          @media (max-width: 992px) {
            display: block;
            padding: 1rem 0;
          }

          &.active,
          &:hover,
          &:focus {
            color: var(--primary-nav-color);
          }
        }
      }
    }
  }
}

@keyframes navbar {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-100px);
  }
}
