.carousel-container {
  background-color: #1a1a1a;
  color: #fff;
  padding: 2rem;
  border-radius: 10px;
  margin: 2rem 0;
}

.carousel-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.review-content {
  max-width: 800px;
  margin: 0 auto;
}

.review-text {
  font-size: 1.25rem;
  font-style: italic;
  margin-bottom: 1rem;
}

.review-author-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.reviewer-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.review-author-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.review-author {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.review-rating {
  font-size: 1.5rem;
  color: #FFD700; /* Gold color for stars */
}

.review-photos {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.review-image {
  max-width: 100px;
  border-radius: 5px;
}

.review-link {
  color: #3774d6;
  text-decoration: none;
  margin-top: 1rem;
  display: inline-block;
}

.review-link:hover {
  text-decoration: underline;
}
