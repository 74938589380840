#home {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 84px);
  padding: 8rem 0 0;

  @media (max-width: 767px) {
    padding: 3rem 0 0;
  }

  .banner_image {
    position: absolute;
    overflow: hidden;
    background: url('../../assets/banner/banner1.jpg') no-repeat center center;
    background-size: cover;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 84px);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      min-height: 100%;
      left: 0;
      right: 0;
      z-index: 2;
      background: var(--dark-layer-color);
      opacity: 0.8; /* Further darkened overlay */
    }
  }

  .banner_outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;

    @media (max-width: 992px) {
      flex-direction: column-reverse;
    }

    .col {
      width: calc(100% / 2 - 15px);
      height: auto;
      position: relative;

      @media (max-width: 992px) {
        width: fit-content;
        padding: 2rem;
      }
      @media (max-width: 767px) {
        padding: 1.5rem;
      }
    }

    .title {
      width: 80%;
      font-size: 4rem;
      line-height: 5rem;
      font-weight: 200;
      margin-bottom: 1rem;
      text-transform: uppercase;
      color: #ffffff;

      & span {
        font-weight: 800;
      }

      @media (max-width: 992px) {
        width: 100%;
        margin-top: 2rem;
        text-align: center;
        line-height: 4rem;
      }

      @media (max-width: 767px) {
        width: 100%;
        font-size: 2.5rem;
        line-height: 3rem;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Increased shadow for more contrast */
      }
    }

    & p {
      width: 80%;
      padding: 0;
      font-size: 1.143rem;
      line-height: 2rem;
      color: var(--font-color);

      @media (max-width: 992px) {
        width: 100%;
        text-align: center;
      }

      @media (max-width: 767px) {
        font-size: 1rem;
        line-height: 1.75rem;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); /* Increased shadow for more contrast */
        background-color: rgba(0, 0, 0, 0.5); /* Add background only on mobile */
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
}
